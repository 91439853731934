import React, { Fragment, useRef, forwardRef, useEffect, useState, useCallback, ReactNode } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import PropTypes from "prop-types"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import throttle from "lodash.throttle"
import { isMobile } from "react-device-detect"
import { Controller, Scene } from "react-scrollmagic"
import { Tween, Timeline } from "react-gsap"
import {
    ResponsiveContainer,
    ComposedChart,
    Bar,
    Area,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from "recharts"


// Components
import LayoutBase from "../components/Layout"
import PageHeader from "../components/PageHeader"
import { getWindowHeight } from "../components/utility"


/**
 * HEMS制御盤
 *
 */
interface IHemsControlPanelDefaultProps {
    location: any,
}

interface IBackgroundImage {
    hemsControlPanel_pageHeader_backgroundImage: any,
}

const hemsControlPanelPage = (props: IHemsControlPanelDefaultProps) => {
    const query: IBackgroundImage = useStaticQuery(graphql`
        query {
            hemsControlPanel_pageHeader_backgroundImage: file(relativePath: {eq: "policy_pageHeader_backgroundImage.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 2500, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        presentationWidth
                    }
                }
            },
        }
    `)
    const pageId = "hemsControlPanel"
    const pageTitle = "HEMS制御盤"
    const location = props.location
    const { crumbs } = useBreadcrumb({
        location: location,
        crumbLabel: pageTitle,
    })


    useEffect(() => {

    }, [])


    return (
        <LayoutBase
            pageId={pageId}
            crumbs={crumbs}
            location={location}
        >
            <PageHeader
                title={pageTitle}
                backgroundImage={query.hemsControlPanel_pageHeader_backgroundImage.childImageSharp.fluid}
                indicators={true}
            />
            <HemsControlPanelOverview/>
            <HemsControlPanelBatteryControl/>
            <HemsControlPanelEnergyMeasurement/>
            <HemsControlPanelPreparingForLongTermPowerOutages/>
            <HemsControlPanelProductSpecifications/>
            <HemsControlPanelCustomMadeToOrder/>
            <div className="common-content-layout common-content-format">
                <GraphTest className="common-content-layout__section"/>
            </div>
        </LayoutBase>
    )
}


/**
 * 概要
 */
interface IHemsControlPanelOverviewProps {

}

const HemsControlPanelOverview = (props: IHemsControlPanelOverviewProps) => {

    
    useEffect(() => {

    }, [])




    return (
        <div
            id="overview"
        >
            概要
        </div>
    )
}


/**
 * 蓄電池のコントロール
 */
interface IHemsControlPanelBatteryControlProps {

}

const HemsControlPanelBatteryControl = (props: IHemsControlPanelBatteryControlProps) => {
    return (
        <div
            id="batteryControl"
        >
            蓄電池のコントロール
        </div>
    )
}


/**
 * エネルギーの計測
 */
interface IHemsControlPanelEnergyMeasurementProps {

}

const HemsControlPanelEnergyMeasurement = (props: IHemsControlPanelEnergyMeasurementProps) => {
    return (
        <div
            id="energyMeasurement"
        >
            エネルギーの計測
        </div>
    )
}


/**
 * 長期停電への備え
 */
interface IHemsControlPanelPreparingForLongTermPowerOutagesProps {

}

const HemsControlPanelPreparingForLongTermPowerOutages = (props: IHemsControlPanelPreparingForLongTermPowerOutagesProps) => {
    return (
        <div
            id="preparingForLongTermPowerOutages"
        >
            長期停電への備え
        </div>
    )
}


/**
 * 製品仕様
 */
interface IHemsControlPanelProductSpecificationsProps {

}

const HemsControlPanelProductSpecifications = (props: IHemsControlPanelProductSpecificationsProps) => {
    return (
        <div
            id="productSpecifications"
        >
            製品仕様
        </div>
    )
}


/**
 * カスタム受注生産
 */
interface IHemsControlPanelCustomMadeToOrderProps {

}

const HemsControlPanelCustomMadeToOrder = (props: IHemsControlPanelCustomMadeToOrderProps) => {
    return (
        <div
            id="customMadeToOrder"
        >
            カスタム受注生産
        </div>
    )
}


/**
 * グラフのテスト
 */
interface IGraphTestDefaultProps {
    style?: React.CSSProperties,
    className?: string,
}

const GraphTest = (props: IGraphTestDefaultProps) => {

    const [playGraph1, setPlayGraph1] = useState<boolean>(false)
    const [dataSet, setDataSet] = useState<any>(dataSetEmpty)

    // グラフ1
    const graph1Ref = useRef<ResponsiveContainer>(null)
    const [graph1XAxisInterval, setGraph1XAxisInterval] = useState<number | "preserveStart" | "preserveEnd" | "preserveStartEnd">(0)
    let graph1Width: number = 0

    // Windowリサイズイベントの実行遅延時間
    const windowResizeDelay = 800 // ms
    // axisの表示を変更するしきい値
    const axisIntervalStyleChangeThreshold = 550

    // グラフ配色
    const colorElectricity1 = "#288cd2"
    const colorElectricity2 = "#28d2b0"
    const colorElectricity3 = "#d2bb28"
    const colorElectricity4 = "#a528d2"
    const colorElectricity5 = "#d2283c"
    const colorPV1 = "#ff9600"


    const setupXAxisInterval = () => {
        if (graph1Width < axisIntervalStyleChangeThreshold) {
            setGraph1XAxisInterval("preserveStartEnd")
            return
        }

        // すべて表示する
        setGraph1XAxisInterval(0)
    }


    const updateGraphWidth = () => {
        if (graph1Ref.current !== null) {
            graph1Width = graph1Ref.current.state.containerWidth
        }
    }


    const onWindowResize = () => {
        updateGraphWidth()
        setupXAxisInterval()
    }


    // 実行間隔を間引いたリサイズ時の処理
    const boundWindowResize = useCallback(throttle(() => {
        onWindowResize()
    }, windowResizeDelay), [])


    useEffect(() => {
        // 遅れて取得する必要のある処理を入れる
        window.setTimeout(() => {
            onWindowResize()
        }, 800)

        if (typeof window !== "undefined") {
            if (isMobile) {
                window.addEventListener("orientationchange", onWindowResize)
            } else {
                window.addEventListener("resize", boundWindowResize)
            }
        }

        return () => {
            if (typeof window !== "undefined") {
                window.removeEventListener("orientationchange", onWindowResize)
                window.removeEventListener("resize", boundWindowResize)
            }
        }
    }, [])


    useEffect(() => {
        if (playGraph1) {
            setDataSet(dataSet1)
        }
    }, [playGraph1])


    return (
        <Controller>
            <Scene duration={600}>
                {(progress: any, event: any) => {
                    if (event.state == "DURING" && !playGraph1) {
                        setPlayGraph1(true)
                    }
                    return (
                        <div className={props.className} style={props.style}>
                            <div className="grid-container">
                                <div className="grid-x">
                                    <div className="cell small-12 medium-7 large-7">

                                        <ResponsiveContainer
                                            ref={graph1Ref}
                                            height={400}
                                        >
                                            <ComposedChart
                                                data={dataSet}
                                                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                                            >
                                                <XAxis
                                                    dataKey="h"
                                                    scale="linear"
                                                    type="number"
                                                    interval={graph1XAxisInterval}
                                                />
                                                <YAxis unit="kWh"/>
                                                <CartesianGrid/>
                                                <Tooltip content={<CustomTooltip/>}/>
                                                <Legend/>
                                                <Bar name="和室" dataKey="e1" stackId="a" fill={colorElectricity1}/>
                                                <Bar name="リビング" dataKey="e2" stackId="a" fill={colorElectricity2}/>
                                                <Line name="太陽光発電量" dataKey="p1" stroke={colorPV1} strokeWidth={2}/>
                                            </ComposedChart>
                                        </ResponsiveContainer>

                                    </div>
                                    <div className="cell small-12 medium-5 large-5">
                                        hoge
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }}
            </Scene>
        </Controller>
    )
}


/**
 * カスタムツールチップ
 */
interface ICustomTooltipProps {
    active?: boolean,
    payload?: any,
    label?: string,
}

const CustomTooltip = (props: ICustomTooltipProps) => {
    if (props.active) {
        return (
            <div className="common-graph-tooltip">
                <ul>
                    <li style={{ backgroundColor: props.payload[0].fill }}>{`${props.payload[0].name}: ${props.payload[0].value} kWh`}</li>
                    <li style={{ backgroundColor: props.payload[1].fill }}>{`${props.payload[1].name}: ${props.payload[1].value} kWh`}</li>
                    <li style={{ backgroundColor: props.payload[2].stroke }}>{`${props.payload[2].name}: ${props.payload[2].value} kWh`}</li>
                </ul>
                <div>
                    {`${props.label} 時`}
                </div>
            </div>
        )
    }

    return null
}


/**
 * 計測値のデータセット
 */
const dataSetEmpty = [
    { h: 1, e1: 0, e2: 0, p1: 0 },
    { h: 2, e1: 0, e2: 0, p1: 0 },
    { h: 3, e1: 0, e2: 0, p1: 0 },
    { h: 4, e1: 0, e2: 0, p1: 0 },
    { h: 5, e1: 0, e2: 0, p1: 0 },
    { h: 6, e1: 0, e2: 0, p1: 0 },
    { h: 7, e1: 0, e2: 0, p1: 0 },
    { h: 8, e1: 0, e2: 0, p1: 0 },
    { h: 9, e1: 0, e2: 0, p1: 0 },
    { h: 10, e1: 0, e2: 0, p1: 0 },
    { h: 11, e1: 0, e2: 0, p1: 0 },
    { h: 12, e1: 0, e2: 0, p1: 0 },
    { h: 13, e1: 0, e2: 0, p1: 0 },
    { h: 14, e1: 0, e2: 0, p1: 0 },
    { h: 15, e1: 0, e2: 0, p1: 0 },
    { h: 16, e1: 0, e2: 0, p1: 0 },
    { h: 17, e1: 0, e2: 0, p1: 0 },
    { h: 18, e1: 0, e2: 0, p1: 0 },
    { h: 19, e1: 0, e2: 0, p1: 0 },
    { h: 20, e1: 0, e2: 0, p1: 0 },
    { h: 21, e1: 0, e2: 0, p1: 0 },
    { h: 22, e1: 0, e2: 0, p1: 0 },
    { h: 23, e1: 0, e2: 0, p1: 0 },
    { h: 24, e1: 0, e2: 0, p1: 0 },
]
const dataSet1 = [
    { h: 1, e1: 0, e2: 0, p1: 0 },
    { h: 2, e1: 0, e2: 0, p1: 0 },
    { h: 3, e1: 0, e2: 0, p1: 0 },
    { h: 4, e1: 0, e2: 0, p1: 0 },
    { h: 5, e1: 0, e2: 0, p1: 0 },
    { h: 6, e1: 0.3, e2: 0.3, p1: 0.1 },
    { h: 7, e1: 0.9, e2: 0.9, p1: 0.1 },
    { h: 8, e1: 1.1, e2: 1.1, p1: 0.2 },
    { h: 9, e1: 0.5, e2: 0.5, p1: 0.4 },
    { h: 10, e1: 0.7, e2: 0.7, p1: 0.8 },
    { h: 11, e1: 0.9, e2: 0.9, p1: 1.4 },
    { h: 12, e1: 0.9, e2: 0.9, p1: 1.9 },
    { h: 13, e1: 1.0, e2: 1.0, p1: 2.2 },
    { h: 14, e1: 1.1, e2: 1.1, p1: 2.2 },
    { h: 15, e1: 0.2, e2: 0.2, p1: 2.0 },
    { h: 16, e1: 0.9, e2: 0.9, p1: 1.5 },
    { h: 17, e1: 0.9, e2: 0.9, p1: 0.9 },
    { h: 18, e1: 1.2, e2: 1.2, p1: 0.8 },
    { h: 19, e1: 1.3, e2: 1.3, p1: 0.1 },
    { h: 20, e1: 1.3, e2: 1.3, p1: 0 },
    { h: 21, e1: 1.0, e2: 1.0, p1: 0 },
    { h: 22, e1: 0.8, e2: 0.8, p1: 0 },
    { h: 23, e1: 0.6, e2: 0.6, p1: 0 },
    { h: 24, e1: 0.3, e2: 0.3, p1: 0 },
]


export default hemsControlPanelPage
